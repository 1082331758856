<template>
  <validation-observer ref="simpleRules">
    <b-card-code title="Tulis Pengajuan">
      <b-row class="match-height">
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Instansi"
            rules="required"
          >
            <b-form-group
              label="Nama Instansi"
              label-for="Nama Instansi"
            >
              <b-form-input
                id="Instansi"
                v-model="instansi"
                placeholder="Input Instansi"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="No. Handphone"
            rules="required"
          >
            <b-form-group
              label="No. Handphone"
              label-for="No. Handphone"
            >
              <b-form-input
                id="number"
                v-model="number"
                placeholder="Input No. Handphone"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Alamat"
            rules="required"
          >
            <b-form-group
              label="Alamat"
              label-for="Alamat"
            >
              <b-form-input
                id="Alamat"
                v-model="address"
                placeholder="Input Alamat"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tanggal"
            rules="required"
          >
            <b-form-group
              label="Tanggal"
              label-for="Tanggal"
            >
              <b-form-datepicker
                id="date-datepicker"
                v-model="date"
                locale="id"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required"
          >
            <b-form-group
              label="Email"
              label-for="Email"
            >
              <b-form-input
                id="Email"
                v-model="email"
                placeholder="Input Email"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="File Surat"
              label-for="FileSurat"
            >
              <!-- Styled -->
              <b-form-file
                id="FileSurat"
                ref="file"
                v-model="file"
                type="file"
                placeholder="Input File Surat"
                drop-placeholder="Drop file here..."
                @change="fileChange"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Kategori"
            label-for="Kategori"
          >
            <v-select
              v-model="kategori"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              placeholder="Pilih Kategori"
              :options="optionsKategori"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tag"
            rules="required"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >
              <b-form-tags
                v-model="tags"
                input-id="tags-basic"
                placeholder="Tambah Tag"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        </b-col>

        <b-col md="6">
          <b-form-group
            label="Divisi/ Bagian/Unit Terkait"
            label-for="selected"
          >
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              multiple
              placeholder="Pilih Divisi"
              :close-on-select="false"
              :options="options"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <label for="textarea-default">Deskripsi</label>
          <b-form-textarea
            id="textarea-default"
            v-model="deskripsi"
            placeholder="Textarea"
            rows="3"
          />
        </b-col>

        <b-col md="6">
          <label for="textarea-default">Catatan</label>
          <b-form-textarea
            id="textarea-default"
            v-model="catatan"
            placeholder="Textarea"
            rows="3"
          />
        </b-col>
      </b-row>

      <b-button
        variant="outline-primary"
        class="bg-gradient-primary "
        type="submit"
        @click.prevent="validationForm"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span class="align-middle">Simpan</span>
      </b-button>

    </b-card-code>
  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import axios from '@axios'
// import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    ToastificationContent,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormDatepicker,
  },
  data() {
    return {
      NoSurat: '',
      instansi: '',
      email: '',
      number: '',
      address: '',
      file: [],
      tags: [],
      date: null,
      kategori: [],
      selected: [],
      deskripsi: '',
      catatan: '',
      options: [{ item: '', name: '' }],
      optionsJenis: [
        { value: 1, text: 'Transaksi' },
        { value: 2, text: 'Non Transaksi' },
      ],
      optionsKategori: [
        { value: 1, text: 'Produk,' },
        { value: 2, text: 'Layanan' },
        { value: 3, text: 'Proses Produksi,' },
        { value: 4, text: 'Pengiriman,' },
        { value: 5, text: 'Paska Pengiriman,' },
        { value: 6, text: 'Harga' },
      ],
    }
  },
  mounted() {
    this.getRole()
  },
  methods: {
    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'Request',
        },
      })
      this.file = data.data
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addRequest()
        }
      })
    },

    async addRequest() {
      const responder = this.selected.map(e => ({
        user_id: e.userID,
        role_id: e.value,
        receiver_type: e.receiverType,
      }))
      await axios
        .post('siap/request/create', {
          cat: this.kategori.text,
          phone: this.number,
          email: this.email,
          institution: this.instansi,
          date: dayjs(this.date).format('YYYY-MM-DDTHH:mm:ss+07:00'),
          address: this.address,
          note: this.catatan,
          desc: this.deskripsi,
          file: this.file,
          tags: this.tags,
          send_to: responder,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          window.location.href = `detail/${response.data.data.ref_num}`
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.errors.map(e => e.message),
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    async getRole() {
      const { data } = await axios.get('siap/account/receiver-type', {
        params: {
          feature: 'Disposition',
          show_all: 'true',
        },
      })
      this.options = data.map(e => ({
        value: e.role.id,
        text: e.role.name,
        userID: e.user.id,
        receiverType: 'Responder',
      }))
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}
</style>
